import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import '../styles/post-site.css'

function PostList() {
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const urlDev = 'https://blog.tflsystems.ddns.net/';
  const urlToUse = urlDev;

  function fetchPosts() {
    fetch(urlToUse + 'posts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setPosts(data);

        // Fetch images for each post
        data.forEach(function (post) {
          fetchImage(post.title_img);
        });
      })
      .catch(function (error) {
        console.error('Error fetching posts:', error);
      });
  }

  function fetchImage(imageName) {
    fetch(urlToUse + 'images/' + imageName, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then(function (response) {
        return response.blob();
      })
      .then(function (imageBlob) {
        const imageUrl = URL.createObjectURL(imageBlob);
        setImageUrls(function (prevImageUrls) {
          return { ...prevImageUrls, [imageName]: imageUrl };
        });
      })
      .catch(function (error) {
        console.error('Error fetching image:', error);
      });
  }

  useEffect(function () {
    document.title = "MrWho's Bückerecke | Startseite";
    const listItems = document.querySelectorAll('.navbar-nav a');
    listItems.forEach(function (item) {
      const itemName = item.getAttribute('data-item');
      if (itemName === 'start') {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
    fetchPosts();
  }, []);

  useEffect(function () {
    const userIds = posts.map(function (post) {
      return post.user_id;
    });
    const uniqueUserIds = Array.from(new Set(userIds));

    uniqueUserIds.forEach(function (userId) {
      fetchUser(userId);
    });
  }, [posts]);

  function fetchUser(userId) {
    fetch(urlToUse + 'user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setUsers(function (prevUsers) {
          return { ...prevUsers, [userId]: data.nickname };
        });
      })
      .catch(function (error) {
        console.error('Error fetching user:', error);
      });
  }

  return (
    <article id='postsite_article'>
      <Container className='postsite-container'>
        {posts.map(function (post) {
          return (
            <Card>
              <Card.Header>{post.title}</Card.Header>
              <Card.Img variant="top" src={imageUrls[post.title_img]} alt={`${post.post_id}`} />
              <Card.Body>
                <Card.Title><i className='fas fa-user-circle'></i> <span>{users[post.user_id]}</span></Card.Title>
                <Container dangerouslySetInnerHTML={{ __html: post.content.replace(/\/images\//g, `${urlToUse}images/`) }}>
                </Container>
              </Card.Body>
            </Card>
          );
        })}
      </Container>
    </article>
  );
}

export default PostList;
