import React, { useState, useEffect } from 'react';

function Imprint() {
  useEffect(function () {
    document.title = "MrWho's Bückerecke | Impressum";
    const listItems = document.querySelectorAll('.navbar-nav a');
    listItems.forEach(function (item) {
      const itemName = item.getAttribute('data-item');
      if (itemName === 'imprint') {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  }, []);

  return (
    <article>
      <div id='post-list' class='imprint-site'>
        <div className='single-post'>
          <h1>Impressum</h1>
          <p>Diese Webseite ist ein Blog für Bücher und wird von mir selbst verwaltet</p>
        </div>
      </div>
    </article>

  );

}

export default Imprint;
