// components/Header.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NavigationHeader() {

  return (
    <Navbar bg='dark' data-bs-theme='dark' expand='xl' className='header' fixed='top'>
      <Navbar.Brand as={Link} to='/'>
        <img
          src="/favicon.ico"
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
        <span>
          MrWho's Bücherecke
        </span>

      </Navbar.Brand>
      <Navbar.Toggle aria-controls='navbar-nav' />
      <Navbar.Collapse id='navbar-nav'>
        <Nav className='ml-auto'>
          <Nav.Link as={Link} to='/' data-item='start'>Startseite</Nav.Link>
          <Nav.Link as={Link} to='/datenschutz' data-item='data-protection'>Datenschutz</Nav.Link>
          <Nav.Link as={Link} to='/impressum' data-item='imprint'>Impressum</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationHeader;
