import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import NavigationHeader from './components/NavigationHeader';

import PostList from './sites/PostList'; // Passe den Pfad entsprechend deiner Projektstruktur an
import DataProtection from './sites/DataProtection'; // Erstelle eine Datenschutz-Komponente
import Imprint from './sites/Imprint'; // Erstelle eine Impressum-Komponente

function App() {
  return (
    <Router>
      <header>
        <NavigationHeader />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<PostList />} />
          <Route path="/datenschutz" element={<DataProtection />} />
          <Route path="/impressum" element={<Imprint />} />
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText="Ich stimme zu"
          cookieName="cookies"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px", background: "#d7c4c4" }}
          expires={150}
        >
          Diese Website verwendet Cookies, um sicherzustellen, dass du die beste Erfahrung auf unserer Website erhältst.
        </CookieConsent>
      </main>
      <footer>
        <p>Copyright &copy; 2024, MrWho</p>
      </footer>

    </Router>

  );
}

export default App;
